import { useNavigate } from "react-router-dom";
import { Button, Modal, StatementExampleTable } from "@moovfinancial/cargo";
import styles from "./ACHVerificationSentModal.module.scss";

interface BankAccountVerifyProps {
  handleBackClick: () => void;
}

export function ACHVerificationSentModal({ handleBackClick }: BankAccountVerifyProps) {
  const navigate = useNavigate();

  const handleComplete = () => {
    navigate("../../review");
  };

  return (
    <Modal isOpen={true} onClose={handleBackClick}>
      <Modal.Header title="Come back to verify this account in 1 business day" />
      <Modal.Body>
        <p>
          We sent a <b>$0.01 deposit</b> with a verification code to your bank account, which can
          take up to 1-2 days to appear. You’ll look for <b>MV</b> followed by 4 numbers, usually
          found in the transaction description of the deposit.
        </p>
        <StatementExampleTable type="ACH" />
        <p className={styles.footnote}>
          Deposits sent before 4:15pm ET will be appear in the account same day. Deposits sent after
          4:15pm ET will appear the following business day.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          buttonSize="M"
          buttonStyle="fill"
          fullWidth
          buttonType="primary"
          onClick={handleComplete}
        >
          Continue
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
