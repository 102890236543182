import type { DeepPartial } from "@moovfinancial/common/types/DeepTypes";
import {
  Action,
  CreateRole,
  MoovAdminResource,
  MoovAdminRole,
  Policy,
  PolicyTable,
  Resource,
  Role,
  RoleTemplate
} from "api/Role.model";
import { http } from "./http";

export function listRoles(facilitatorAccountID: string): Promise<Role[]> {
  return http("/roles", { xAccountID: facilitatorAccountID });
}

export function getRole(facilitatorAccountID: string, roleID: string): Promise<Role> {
  return http(`/roles/${roleID}`, { xAccountID: facilitatorAccountID });
}

export async function getUserRole(
  facilitatorAccountID: string,
  userID: string
): Promise<Role | undefined> {
  const roles = await listRoles(facilitatorAccountID);
  return roles.find((r) => r.subjects.includes(userID));
}

export function createRole(facilitatorAccountID: string, role: CreateRole): Promise<Role> {
  return http("/roles", {
    method: "POST",
    xAccountID: facilitatorAccountID,
    json: role
  });
}

export function updateRole(facilitatorAccountID: string, role: Role): Promise<Role> {
  return http(`/roles/${role.roleID}`, {
    method: "PUT",
    xAccountID: facilitatorAccountID,
    json: role
  });
}

export function deleteRole(facilitatorAccountID: string, role: Role): Promise<null> {
  return http(`/roles/${role.roleID}`, {
    method: "DELETE",
    xAccountID: facilitatorAccountID
  });
}

export const policy = (resource: Resource, action: Action): Policy => ({
  resource,
  action
});

export const renderPolicy =
  (accountID: string) =>
  (policy?: DeepPartial<Policy>): DeepPartial<Policy> => ({
    ...(policy ?? {}),
    resource: policy?.resource?.replace("{accountID}", accountID)
  });

// prettier-ignore
export const policyTable: Record<string, PolicyTable> = {
  Developer: {
  // resource                    read   write
    [Resource.Accounts]:        [true,  false],
    [Resource.ApplePay]:        [false,  false],
    [Resource.Applications]:    [true,  true],
    [Resource.BankAccounts]:    [true,  false],
    [Resource.Capabilities]:    [false, false],
    [Resource.Cards]:           [true,  false],
    [Resource.Connections]:     [true,  true],
    [Resource.Files]:           [true,  true],
    [Resource.Invites]:         [true,  false],
    [Resource.Keys]:            [true,  true],
    [Resource.PaymentMethods]:  [true,  false],
    [Resource.Profile]:         [true,  false],
    [Resource.Representatives]: [true,  false],
    [Resource.Roles]:           [true,  false],
    [Resource.Transfers]:       [true,  false],
    [Resource.Underwriting]:    null,
    [Resource.Wallets]:         [true,  false],
    [Resource.Webhooks]:        [true,  true],
  },
  Support: {
  // resource                    read   write
    [Resource.Accounts]:        [true,  false],
    [Resource.ApplePay]:        [false,  false],
    [Resource.Applications]:    [false, false],
    [Resource.BankAccounts]:    [true,  false],
    [Resource.Capabilities]:    [false, false],
    [Resource.Cards]:           [true,  false],
    [Resource.Connections]:     [true,  true],
    [Resource.Files]:           [true,  true],
    [Resource.Invites]:         [true,  false],
    [Resource.Keys]:            [false, false],
    [Resource.PaymentMethods]:  [true,  false],
    [Resource.Profile]:         [true,  false],
    [Resource.Representatives]: [false, false],
    [Resource.Roles]:           [true,  false],
    [Resource.Transfers]:       [true,  false],
    [Resource.Underwriting]:    null,
    [Resource.Wallets]:         [true,  false],
    [Resource.Webhooks]:        [false, false],
  },
  ViewOnly: {
  // resource                    read   write
    [Resource.Accounts]:        [true,  false],
    [Resource.ApplePay]:        [false,  false],
    [Resource.Applications]:    [false, false],
    [Resource.BankAccounts]:    [true,  false],
    [Resource.Capabilities]:    [false, false],
    [Resource.Cards]:           [true,  false],
    [Resource.Connections]:     [true,  false],
    [Resource.Files]:           [true,  false],
    [Resource.Invites]:         [true,  false],
    [Resource.Keys]:            [false, false],
    [Resource.PaymentMethods]:  [true,  false],
    [Resource.Profile]:         [true,  false],
    [Resource.Representatives]: [false, false],
    [Resource.Roles]:           [true,  false],
    [Resource.Transfers]:       [true,  false],
    [Resource.Underwriting]:    null,
    [Resource.Wallets]:         [true,  false],
    [Resource.Webhooks]:        [false, false],
  }
};

// prettier-ignore
export const moovAdminPolicyTable: Partial<Record<string, Partial<Record<Action, MoovAdminRole[]>>>> = {
  [MoovAdminResource.adminUnderwriting]: {
    read:  ["support", "risk"],
    write: ["risk"]
  },
  [MoovAdminResource.adminVerification]: {
    read:  ["support", "risk"],
    write: ["risk"]
  },
  [MoovAdminResource.adminMatch]: {
    read:  ["support", "risk"],
    write: ["risk"]
  },
  [MoovAdminResource.statements]: {
    read:  ["support", "risk"],
    write: ["support", "risk"]
  },
  [MoovAdminResource.bankAccountBypass]: {
    read:  ["support", "risk"],
    write: ["risk"]
  },
  [MoovAdminResource.achConfig]: {
    read:  ["support", "risk"],
    write: ["risk"]
  },
  [MoovAdminResource.adminDisputes]: {
    read:  ["support"],
    write: ["support"],
  },
  [MoovAdminResource.disputeEvidence]: {
    read:  ["support"],
    write: ["support"],
  },
  [MoovAdminResource.adminRepVerification]: {
    read:  ["support", "risk"],
    write: ["risk"]
  },
  [MoovAdminResource.adminBankAccounts]: {
    read: ["risk"],
    write: ["risk"]
  },
  [MoovAdminResource.lookbackReplay]: {
    read:  ["support", "risk"],
    write: ["risk"]
  },
  [MoovAdminResource.encryptedData]: {
    read:  ["risk"]
  },
  [MoovAdminResource.adminAccountPatch]: {
    read:  ["support", "risk"],
    write: ["support", "risk"]
  },
  [MoovAdminResource.documentStatus]: {
    read: ["support", "risk"],
    write: ["risk"]
  },
  [MoovAdminResource.writeFinance]: {
    write: ["finance"]
  },
  [MoovAdminResource.writeRisk]: {
    write: ["risk"]
  },
  [MoovAdminResource.writeSupport]: {
    write: ["support", "risk"]
  }
};

export const defaultRoles: RoleTemplate[] = [
  {
    name: "Developer",
    policies: reducePolicyTableToArray(policyTable.Developer),
    subjects: []
  },
  {
    name: "Support",
    policies: reducePolicyTableToArray(policyTable.Support),
    subjects: []
  },
  {
    name: "View only",
    policies: reducePolicyTableToArray(policyTable.ViewOnly),
    subjects: []
  }
];

function reducePolicyTableToArray(table: PolicyTable): Policy[] {
  return Object.keys(table).reduce<Policy[]>((policies, curr) => {
    const res = curr as Resource;
    const actions = table[res];

    if (actions) {
      if (actions[0]) policies.push(policy(res, Action.Read));
      if (actions[1]) policies.push(policy(res, Action.Write));
    }

    return policies;
  }, []);
}
