import { http, setConfig } from "./http";
import { silentFetch } from "./v2/silentFetch";

export async function hasFinanceRead(): Promise<boolean> {
  try {
    setConfig({ fetchFn: silentFetch });
    const promise = http("/session/policies/finance.read");
    setConfig({ fetchFn: fetch });
    await promise;
    return true;
  } catch {
    return false;
  }
}

export async function hasFinanceWrite(): Promise<boolean> {
  try {
    setConfig({ fetchFn: silentFetch });
    const promise = http("/session/policies/finance.write");
    setConfig({ fetchFn: fetch });
    await promise;
    return true;
  } catch {
    return false;
  }
}

export async function hasSupportRead(): Promise<boolean> {
  try {
    setConfig({ fetchFn: silentFetch });
    const promise = http("/session/policies/support.read");
    setConfig({ fetchFn: fetch });
    await promise;
    return true;
  } catch {
    return false;
  }
}

export async function hasSupportWrite(): Promise<boolean> {
  try {
    setConfig({ fetchFn: silentFetch });
    const promise = http("/session/policies/support.write");
    setConfig({ fetchFn: fetch });
    await promise;
    return true;
  } catch {
    return false;
  }
}

export async function hasRiskRead(): Promise<boolean> {
  try {
    setConfig({ fetchFn: silentFetch });
    const promise = http("/session/policies/risk.read");
    setConfig({ fetchFn: fetch });
    await promise;
    return true;
  } catch {
    return false;
  }
}

export async function hasRiskWrite(): Promise<boolean> {
  try {
    setConfig({ fetchFn: silentFetch });
    const promise = http("/session/policies/risk.write");
    setConfig({ fetchFn: fetch });
    await promise;
    return true;
  } catch {
    return false;
  }
}
