import { useContext, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { UserContext } from "contexts/UserContext";

/** Determines if the current route is an admin route and if the current user is a super user. Returns true if both requirements are met. */
export const useAuthenticatedAdminRoute = () => {
  const currentLocation = useLocation();
  const { isSuper } = useContext(UserContext);

  return useMemo(
    () => currentLocation.pathname.startsWith("/admin") && isSuper,
    [currentLocation.pathname, isSuper]
  );
};
