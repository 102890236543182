"use client";

import clsx from "clsx";
import { Link, useMatch } from "react-router-dom";
import SvgrReactComponent from "vite-plugin-svgr/*.svg?react";
import { CargoElement, CargoElementProps } from "../../CargoElement";
import { Badge } from "../../Foundations";
import { IconCheckmarkCircle, IconCircleOutlined } from "../../Icons";
import { Icon } from "../../Icons/Icon";
import { toToString } from "./StepperUtils";
import {
  NavigationStep as NavigationStepType,
  StaticStep as StaticStepType,
  StepOrientation,
  Step as StepType,
  isStepNavigable
} from "./step.model";
import styles from "./Step.module.scss";

export interface StepProps extends Omit<CargoElementProps, "children"> {
  /**
   * The icon to override the active step icon. This will apply to the individual step.
   */
  activeIcon?: typeof SvgrReactComponent;
  /**
   * Whether to hide the badge.
   */
  hideBadge?: boolean;
  /**
   * Whether the line is invisible.
   */
  invisibleLine?: boolean;
  /**
   * Whether the step is active.
   */
  isActive?: boolean;
  /**
   * Whether the stepper is in navigation mode.
   */
  isNavigationMode?: boolean;
  /**
   * The orientation of the stepper. Defaults to "vertical".
   * Horizontal orientation cannot support more than one level of steps.
   */
  orientation?: StepOrientation;
  /**
   * The step to render in the stepper.
   */
  step: StepType;
}

interface NavigationStepProps extends StepProps {
  step: NavigationStepType;
}

interface StaticStepProps extends StepProps {
  step: StaticStepType;
}

const NavigationStepWrapper = ({ step, ...rest }: NavigationStepProps) => {
  const match = useMatch({ end: false, path: toToString(step.to) });
  const isActive = !!match;
  return <InnerStep isActive={isActive} step={step} {...rest} />;
};

const StaticStepWrapper = ({ step, ...rest }: StaticStepProps) => {
  const { isActive } = step;
  return <InnerStep isActive={isActive} step={step} {...rest} />;
};

const InnerStep = ({
  activeIcon: ActiveIcon,
  className,
  hideBadge,
  invisibleLine,
  isActive,
  isNavigationMode,
  orientation = "vertical",
  step,
  ...props
}: StepProps) => {
  const renderContent = (children: React.ReactNode) => {
    if (isNavigationMode && isStepNavigable(step)) {
      return <Link to={step.to}>{children}</Link>;
    }
    return <span>{children}</span>;
  };

  const getIconName = () => {
    if (step.isComplete) {
      return IconCheckmarkCircle;
    }
    if (isActive && ActiveIcon) {
      return ActiveIcon;
    }
    return IconCircleOutlined;
  };

  return (
    <CargoElement
      className={clsx(
        styles.step,
        styles[orientation],
        {
          [styles.isComplete]: step.isComplete,
          [styles.isActive]: isActive
        },
        className
      )}
      data-testid={isActive ? "active-step" : undefined}
      {...props}
    >
      <div className={styles.timeline}>
        {orientation === "horizontal" && (
          <div className={clsx(styles.timelineLine, invisibleLine && styles.invisibleLine)} />
        )}
        {!hideBadge && (
          <Badge
            className={styles.timelineIcon}
            Icon={<Icon iconComponent={getIconName()} />}
            label="step icon"
            noLabel
          />
        )}
        <div className={clsx(styles.timelineLine, invisibleLine && styles.invisibleLine)} />
      </div>
      <div className={styles.content}>
        {step.children?.length && orientation === "vertical" && isActive ? (
          <>
            {renderContent(step.Component)}
            {step.children.map((childStep, childStepIndex) => (
              <Step
                hideBadge
                invisibleLine
                isNavigationMode={isNavigationMode}
                key={childStepIndex}
                orientation={orientation}
                step={childStep}
              />
            ))}
          </>
        ) : (
          renderContent(step.Component)
        )}
      </div>
    </CargoElement>
  );
};

export const Step = ({ isNavigationMode, step, ...rest }: StepProps) => {
  if (isNavigationMode) {
    return (
      <NavigationStepWrapper isNavigationMode={true} step={step as NavigationStepType} {...rest} />
    );
  }
  return <StaticStepWrapper isNavigationMode={false} step={step} {...rest} />;
};
