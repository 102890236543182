import clsx from "clsx";
import styles from "./PageHeader.module.scss";

export interface PageHeaderProps {
  noPadding?: boolean;
  rightSideContent?: React.ReactNode;
  secondaryLogo?: React.ReactNode;
  testMode?: boolean;
}

export const PageHeader = ({
  noPadding = false,
  rightSideContent,
  secondaryLogo: secondaryIcon,
  testMode
}: PageHeaderProps) => {
  return (
    <header className={clsx(styles.header, noPadding && styles.noPadding)}>
      {testMode && (
        <div className={styles.notchRoot}>
          <div className={styles.notch}>Viewing test account</div>
        </div>
      )}
      <div className={styles.logo}>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="69"
            height="20"
            viewBox="0 0 69 20"
            fill="none"
          >
            <path d="M18.5205 2.53983H0.510056C0.234896 2.53983 0.00976562 2.76022 0.00976562 3.02958V16.9998C0.00976562 17.3304 0.22239 17.5508 0.522564 17.5508H5.96322C6.23837 17.5508 6.46352 17.3304 6.46352 17.061V6.04157C6.46352 5.94362 6.55104 5.85791 6.6511 5.85791H7.7768C7.87686 5.85791 7.96437 5.94362 7.96437 6.04157V17.061C7.96437 17.3304 8.18952 17.5508 8.46467 17.5508H12.3294C12.6046 17.5508 12.8297 17.3304 12.8297 17.061V6.04157C12.8297 5.94362 12.9173 5.85791 13.0173 5.85791H14.1555C14.2555 5.85791 14.3431 5.94362 14.3431 6.04157V17.061C14.3431 17.3304 14.5682 17.5508 14.8433 17.5508H20.309C20.5842 17.5508 20.8093 17.3304 20.8093 17.061V5.00084C20.8469 3.53158 19.9338 2.53983 18.5205 2.53983Z" />
            <path d="M67.9119 2.53983H62.4458C62.1707 2.53983 61.9458 2.76022 61.9458 3.02958V14.0491C61.9458 14.147 61.8579 14.2327 61.7579 14.2327H60.6325C60.5324 14.2327 60.4449 14.147 60.4449 14.0491V3.02958C60.4449 2.76022 60.2197 2.53983 59.9445 2.53983H54.4789C54.1662 2.53983 53.9661 2.76022 53.9661 3.0908V7.9761C53.9661 8.02507 53.9661 8.07404 53.9786 8.12301L55.5045 15.9101C55.6921 16.8651 56.1048 17.5508 57.0554 17.5508H65.3604C66.3107 17.5508 66.7234 16.8651 66.9113 15.9101L68.4367 8.12301C68.4495 8.07404 68.4495 8.02507 68.4495 7.9761V3.0908C68.4246 2.76022 68.2119 2.53983 67.9119 2.53983Z" />
            <path d="M34.4043 2.53983H24.5861C23.1603 2.53983 22.2597 3.51933 22.2597 5.00084V15.0898C22.2597 16.5713 23.1603 17.5508 24.5861 17.5508H34.4043C35.8301 17.5508 36.7306 16.5713 36.7306 15.0898V5.00084C36.7306 3.53158 35.8301 2.53983 34.4043 2.53983ZM30.2518 14.0613C30.2518 14.1593 30.1643 14.245 30.0643 14.245H28.9386C28.8386 14.245 28.751 14.1593 28.751 14.0613V6.04157C28.751 5.94362 28.8386 5.85791 28.9386 5.85791H30.0643C30.1643 5.85791 30.2518 5.94362 30.2518 6.04157V14.0613Z" />
            <path d="M50.3021 2.53983H40.4839C39.0581 2.53983 38.1576 3.51933 38.1576 5.00084V15.0898C38.1576 16.5713 39.0581 17.5508 40.4839 17.5508H50.3021C51.728 17.5508 52.6284 16.5713 52.6284 15.0898V5.00084C52.6284 3.53158 51.728 2.53983 50.3021 2.53983ZM46.1497 14.0613C46.1497 14.1593 46.0621 14.245 45.9621 14.245H44.8364C44.7364 14.245 44.6489 14.1593 44.6489 14.0613V6.04157C44.6489 5.94362 44.7364 5.85791 44.8364 5.85791H45.9621C46.0621 5.85791 46.1497 5.94362 46.1497 6.04157V14.0613Z" />
          </svg>
        </div>

        {!!secondaryIcon && (
          <>
            <div className={styles.plus}>+</div>
            <div>{secondaryIcon}</div>
          </>
        )}
      </div>
      {!!rightSideContent && (
        <>
          <div>{rightSideContent}</div>
        </>
      )}
    </header>
  );
};
