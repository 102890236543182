import type { components } from "@moovfinancial/common/types/__generated-types__/api";
import { formatDollars } from "@moovfinancial/common/utils/format/formatCurrency";
import { formatPercentInput } from "@moovfinancial/common/utils/format/formatPercent";
import styles from "./FeeRow.module.scss";

type FeePlan = components["schemas"]["FeePlan"];
type Fee = Omit<components["schemas"]["Fee"], "feeModel" | "feeCategory"> & {
  // TODO FE-1224 : openApiSpecIsWrong - "monthly-platform" is missing from Fee["feeCategory"]
  feeCategory: components["schemas"]["Fee"]["feeCategory"] | "monthly-platform";
};

export function FeeRow({ fee, feePlan }: { fee: Fee; feePlan: FeePlan }) {
  const { minPerTransaction, maxPerTransaction, variableRate, fixedAmount } =
    fee.feeProperties || {};

  /* For certain fees, append "+ interchange & network fees" or "+ network fees" to the rate display. */
  function MaybeInterchangeAndNetworkFees() {
    if (feePlan.cardAcquiringModel !== "cost-plus") return undefined;
    if (fee.billableEvent !== "card-settled-volume" && fee.billableEvent !== "card-auth-volume")
      return undefined;
    return <div className={styles.priceDetails}>+ interchange & network fees</div>;
  }

  /* If `feeCategory` is "monthly-platform", append " per month" to the rate display. */
  function MaybePerMonth() {
    if (fee.feeCategory !== "monthly-platform") return undefined;
    return <div className={styles.priceDetails}>Per month</div>;
  }

  /* Shows the min & max dollar amounts of the charge, if set */
  function MaybeMinMax() {
    if (!minPerTransaction && !maxPerTransaction) return undefined;
    return (
      <div className={styles.priceDetails}>
        {minPerTransaction && `Min: ${formatDollars(Number(minPerTransaction))}`}
        {minPerTransaction && maxPerTransaction && ", "}
        {maxPerTransaction && `Max: ${formatDollars(Number(maxPerTransaction))}`}
      </div>
    );
  }

  return (
    <div className={styles.row} key={fee.billableFeeID}>
      <div className={styles.name}>{fee.feeName}</div>
      <div className={styles.right}>
        <div className={styles.price}>
          {variableRate && formatPercentInput(variableRate)}
          {variableRate && fixedAmount && " + "}
          {fixedAmount && formatDollars(Number(fixedAmount))}
        </div>
        <MaybePerMonth />
        <MaybeInterchangeAndNetworkFees />
        <MaybeMinMax />
      </div>
    </div>
  );
}
