import clsx from "clsx";
import { BaseButton } from "../../Actions";
import { IconChevronUp } from "../../Icons";
import { Icon } from "../../Icons/Icon";
import styles from "./Category.module.scss";

interface CategoryProps {
  /** Title of the category */
  title: string;
  /** Description of the category */
  description: string;
  /** Determines the number of columns displayed on larger screens */
  responsive?: boolean;
  /** Whether the category is collapsed. If true, subcategories are hidden and collapse button is shown */
  collapsed?: boolean;
  /** Whether the category is expanded. If true, subcategories are visible and collapse button is shown */
  expanded?: boolean;
  /** Callback function for when the collapse button is clicked */
  onCollapseButtonClick?: () => void;
  /** Children of the category. Should be FeeRow components */
  children: React.ReactNode;
}

export function Category({
  title,
  description,
  responsive = true,
  collapsed = false,
  expanded = false,
  onCollapseButtonClick,
  children
}: CategoryProps) {
  return (
    <div
      className={clsx(styles.category, expanded && styles.expanded, collapsed && styles.collapsed)}
    >
      <div className={styles.categoryAnchor} id={title.replaceAll(" ", "-")} />
      <div className={clsx(styles.categoryGrid, responsive && styles.responsive)}>
        <div className={styles.categoryHeading}>
          {(expanded || collapsed) && (
            <BaseButton
              className={clsx(styles.icon, collapsed && styles.collapsed)}
              onClick={onCollapseButtonClick}
            >
              <Icon size={1} iconComponent={IconChevronUp} />
            </BaseButton>
          )}
          <h3 className={styles.categoryTitle}>{title}</h3>
          {!!description && !collapsed && (
            <p className={styles.categoryDescription}>{description}</p>
          )}
        </div>
        <div className={styles.feeRows}>{children}</div>
      </div>
    </div>
  );
}
