import clsx from "clsx";
import { useEffect } from "react";
import { Theme } from "@moovfinancial/common/types/Theme";
import styles from "./CategoryLinks.module.scss";

type DisplayCategory =
  | "Card acquiring"
  | "Instant payments"
  | "Other card fees"
  | "ACH"
  | "Platform & other fees";

export interface CategoryLinksProps {
  categories: DisplayCategory[];
  /** Theme overrides for the component */
  theme?: Theme<typeof styles>;
}

const categoryToID = (category: DisplayCategory) =>
  category.replaceAll(" ", "-").replaceAll("&", "and");

/** A row of links that take the user to the corresponding pricing category on the page */
export function CategoryLinks({ categories, theme }: CategoryLinksProps) {
  // Highlight the category links based on scroll position
  useEffect(() => {
    const categoryIDs = categories.map(categoryToID);
    const intersectionObservers: IntersectionObserver[] = [];
    categoryIDs.forEach((id) => {
      const categoryLink = document.querySelector<HTMLAnchorElement>(`a[href="#${id}"]`);
      const categoryElement = document.querySelector<HTMLDivElement>(`div#${id}`);
      if (categoryLink && categoryElement) {
        const intersectionObserver = new IntersectionObserver(
          (entries) => {
            categoryLink.classList.toggle(styles.active, entries[0].isIntersecting);
          },
          { threshold: 1.0 }
        );
        intersectionObserver.observe(categoryElement);
        intersectionObservers.push(intersectionObserver);
      }
    });
    // Clean up intersection observers
    return () => {
      intersectionObservers.forEach((observer) => observer.disconnect());
    };
  }, [categories]);

  return (
    <div className={clsx(styles.categoryLinks, theme?.categoryLinks)}>
      {categories.map((category) => (
        <a key={category} href={`#${categoryToID(category)}`}>
          {category}
        </a>
      ))}
    </div>
  );
}
