import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Alert, Button, Icon, LegacyCard, Nameplate } from "@moovfinancial/cargo";
import { IconErrorOutlined } from "@moovfinancial/cargo/icons";
import { backupIndustryCodes, getTitleByCodes } from "@moovfinancial/common/utils/Industry";
import { formatCurrencyInDollars } from "@moovfinancial/common/utils/format/formatCurrency";
import { stringToFormattedEIN } from "@moovfinancial/common/utils/format/formatEIN";
import { formatPhoneForDB } from "@moovfinancial/common/utils/format/formatPhone";
import UserAvatar from "components/avatar/UserAvatar";
import BankLogo from "components/bank-logo/BankLogo";
import { Checkbox } from "components/form/Form";
import DetailItem from "components/lists/DetailItem";
import { Account as LegacyAccount, Representative as LegacyRepresentative } from "api/v2";
import { OnboardingContext, Representative } from "contexts/OnboardingContext";
import { OnboardingInviteContext } from "contexts/OnboardingInviteContext";
import { OnboardingStepsContext } from "contexts/OnboardingStepsContext";
import AccountStatus from "pages/accounts/AccountDetail/overview/payment-methods/AccountStatus";
import RepresentativeListItem from "pages/production-access-form/representatives/RepresentativeListItem";
import { FooterButtons } from "../FooterButtons";
import { PricingSection } from "./PricingSection";
import styles from "./ReviewStep.module.scss";

function hasController(representatives: Representative[]) {
  return representatives.some(
    (representative) => representative.responsibilities?.isController === true
  );
}

const MissingInfoWarning = () => (
  <span className={styles.missingInfoWarning}>
    <Icon iconComponent={IconErrorOutlined} /> Missing information
  </span>
);

export function ReviewStep() {
  const {
    account,
    bankAccounts,
    files,
    representatives,
    underwriting,
    fetchTosToken,
    patchAccount
  } = useContext(OnboardingContext);
  const navigate = useNavigate();
  const { invite } = useContext(OnboardingInviteContext);
  const { getNextStepUrl, hasExpectedActivityStep, hasOwnersStep, isComplete } =
    useContext(OnboardingStepsContext);
  const [tosAgreed, setTosAgreed] = useState(false);
  const [tosToken, setTosToken] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const phoneValue = formatPhoneForDB(account.profile?.business?.phone?.number || "");

  const missingController = useMemo(
    () => !representatives.length || !hasController(representatives),
    [representatives]
  );

  const getTos = useCallback(async () => {
    const { data, error } = await fetchTosToken();

    if (error) {
      setTosAgreed(false);
      toast.error("Something went wrong. Please try again.");
      return;
    }

    if (data && data.token) {
      setTosToken(data.token);
    }
  }, [fetchTosToken]);

  const handleSubmit = useCallback(async () => {
    if (!account.accountID || !tosToken) return;
    setIsLoading(true);

    const { data, error } = await patchAccount({
      ...account,
      termsOfService: { token: tosToken }
    });

    if (data && !error) {
      navigate(getNextStepUrl());
    } else {
      toast.error("There was a problem submitting. Try again.");
      setIsLoading(false);
    }
  }, [account, getNextStepUrl, navigate, patchAccount, tosToken]);

  useEffect(() => {
    // Fetch ToS token when checkbox is checked, but only once
    if (tosAgreed && !tosToken) {
      void getTos();
    }
  }, [getTos, tosAgreed, tosToken]);

  return (
    <div className={styles.content}>
      <Helmet>
        <title>Review & finish</title>
      </Helmet>
      <h2 className={styles.header}>Review & finish</h2>
      <p>Please double-check the information below before finishing.</p>

      {!isComplete && (
        <Alert className={styles.alert} type="warning">
          Some section are still missing required information. Please complete all sections to
          finish creating your account.
        </Alert>
      )}

      <LegacyCard className={styles.card}>
        <LegacyCard.Header hasButton>
          <Nameplate
            title={account.profile?.business?.legalBusinessName || "Missing business name"}
            subtitle={account.profile?.business?.doingBusinessAs}
            Icon={<UserAvatar account={account as LegacyAccount} sizeRem={2.5} />}
          />
          <Button
            type="button"
            buttonSize="XS"
            buttonStyle="text"
            buttonType="secondary"
            onClick={() => navigate("../business/information")}
          >
            Edit
          </Button>
        </LegacyCard.Header>
        <LegacyCard.Body>
          <dl>
            <DetailItem label="Industry">
              {account.profile?.business?.industryCodes ? (
                getTitleByCodes(account.profile.business.industryCodes, backupIndustryCodes)
              ) : (
                <MissingInfoWarning />
              )}
            </DetailItem>
            <DetailItem label="EIN">
              {account.profile?.business?.taxID?.ein?.number ? (
                stringToFormattedEIN(account.profile.business.taxID.ein.number)
              ) : account.profile?.business?.taxIDProvided ? (
                "EIN ••-•••••••"
              ) : (
                <MissingInfoWarning />
              )}
            </DetailItem>
            <DetailItem label="Email">
              {account.profile?.business?.email || <MissingInfoWarning />}
            </DetailItem>
            <DetailItem label="Phone number">{phoneValue || <MissingInfoWarning />}</DetailItem>
            <DetailItem label="Website">
              {account.profile?.business?.website || <MissingInfoWarning />}
            </DetailItem>
            <DetailItem label="Business description / Use case">
              {account.profile?.business?.description || <MissingInfoWarning />}
            </DetailItem>
            <DetailItem label="Legal address">
              {account.profile?.business?.address?.addressLine1 ? (
                <>
                  <div>{account.profile?.business?.address.addressLine1}</div>
                  {account.profile?.business?.address.addressLine2 && (
                    <div>{account.profile?.business?.address.addressLine2}</div>
                  )}
                  <div>
                    {account.profile?.business?.address.city},{" "}
                    {account.profile?.business?.address.stateOrProvince}{" "}
                    {account.profile?.business?.address.postalCode}
                  </div>
                </>
              ) : (
                <div>
                  <MissingInfoWarning />
                </div>
              )}
            </DetailItem>
            <DetailItem label="Company address">
              {account.customerSupport?.address?.addressLine1 ? (
                <>
                  <div>{account.customerSupport.address.addressLine1}</div>
                  {account.customerSupport.address.addressLine2 && (
                    <div>{account.customerSupport.address.addressLine2}</div>
                  )}
                  <div>
                    {account.customerSupport.address.city},{" "}
                    {account.customerSupport.address.stateOrProvince}{" "}
                    {account.customerSupport.address.postalCode}
                  </div>
                </>
              ) : (
                <div>
                  <MissingInfoWarning />
                </div>
              )}
            </DetailItem>
          </dl>
        </LegacyCard.Body>
      </LegacyCard>

      {hasOwnersStep() && (
        <LegacyCard className={styles.card}>
          <LegacyCard.Header hasButton>
            <LegacyCard.Title>Owners & officers</LegacyCard.Title>
            <Button
              type="button"
              buttonSize="XS"
              buttonStyle="text"
              buttonType="secondary"
              onClick={() => navigate("../owners")}
            >
              Edit
            </Button>
          </LegacyCard.Header>
          <LegacyCard.Body>
            {representatives.map((representative: Representative) => (
              <RepresentativeListItem
                className={styles.listItem}
                key={representative.representativeID}
                missingController={missingController}
                onEdit={() => navigate(`../owners/edit/${representative.representativeID}`)}
                representative={representative as LegacyRepresentative}
              />
            ))}
            {representatives.length === 0 && <MissingInfoWarning />}
          </LegacyCard.Body>
        </LegacyCard>
      )}

      {hasExpectedActivityStep() && (
        <LegacyCard className={styles.card}>
          <LegacyCard.Header hasButton>
            <LegacyCard.Title>Expected activity</LegacyCard.Title>
            <Button
              type="button"
              buttonSize="XS"
              buttonStyle="text"
              buttonType="secondary"
              onClick={() => navigate("../expected-activity")}
            >
              Edit
            </Button>
          </LegacyCard.Header>
          <LegacyCard.Body>
            <dl>
              <DetailItem label="Average monthly volume">
                {!!underwriting?.averageMonthlyTransactionVolume &&
                underwriting.averageMonthlyTransactionVolume !== 0 ? (
                  formatCurrencyInDollars(underwriting.averageMonthlyTransactionVolume)
                ) : (
                  <MissingInfoWarning />
                )}
              </DetailItem>
              {underwriting?.volumeByCustomerType?.businessToBusinessPercentage &&
              underwriting.volumeByCustomerType.businessToBusinessPercentage > 0 ? (
                <DetailItem label="Business-to-business volume">
                  `${underwriting.volumeByCustomerType.businessToBusinessPercentage}%`
                </DetailItem>
              ) : null}
              {underwriting?.volumeByCustomerType?.consumerToBusinessPercentage &&
              underwriting.volumeByCustomerType.consumerToBusinessPercentage > 0 ? (
                <DetailItem label="Consumer-to-business volume">
                  `${underwriting.volumeByCustomerType.consumerToBusinessPercentage}%`
                </DetailItem>
              ) : null}
              <DetailItem label="Average transaction amount">
                {underwriting?.averageTransactionSize &&
                underwriting?.averageTransactionSize > 0 ? (
                  formatCurrencyInDollars(underwriting?.averageTransactionSize)
                ) : (
                  <MissingInfoWarning />
                )}
              </DetailItem>
              <DetailItem label="Maximum transaction amount">
                {underwriting?.maxTransactionSize && underwriting?.maxTransactionSize > 0 ? (
                  formatCurrencyInDollars(underwriting?.maxTransactionSize)
                ) : (
                  <MissingInfoWarning />
                )}
              </DetailItem>
              <DetailItem label="Processing statements">
                {files.length > 0 ? (
                  files.map((file, i) => (
                    <div className={styles.file} key={i}>
                      {file.fileName}
                    </div>
                  ))
                ) : (
                  <>None provided</>
                )}
              </DetailItem>
            </dl>
          </LegacyCard.Body>
        </LegacyCard>
      )}

      <LegacyCard className={styles.card}>
        <LegacyCard.Header hasButton>
          <LegacyCard.Title>Bank account</LegacyCard.Title>
          <Button
            type="button"
            buttonSize="XS"
            buttonStyle="text"
            buttonType="secondary"
            onClick={() => navigate("../bank-account")}
          >
            Edit
          </Button>
        </LegacyCard.Header>
        <LegacyCard.Body>
          {bankAccounts.map((bankAccount) => (
            <Nameplate
              title={`${bankAccount.bankAccountType} ••••${bankAccount.lastFourAccountNumber}`}
              subtitle={bankAccount.bankName}
              Icon={<BankLogo id={bankAccount.routingNumber} size={2.5} />}
              capitalize
              key={bankAccount.bankAccountID}
              rightContent={<AccountStatus noLabel={false} status={bankAccount.status} />}
              theme={{
                nameplate: styles.listItem,
                rightContent: styles.rightContent
              }}
            />
          ))}
          {bankAccounts.length === 0 && <MissingInfoWarning />}
        </LegacyCard.Body>
      </LegacyCard>

      {invite && <PricingSection planIDs={invite.feePlanCodes} />}

      <div className={styles.tosAgree}>
        <Checkbox checked={tosAgreed} id="tos" onChange={(e) => setTosAgreed(e.target.checked)} />
        <label htmlFor="tos" className={styles.tosAgreeLabel}>
          By clicking "Finish", you agree to the terms of Moov's{" "}
          <a target="_blank" rel="noreferrer" href="https://moov.io/legal/privacy-policy/">
            Privacy Policy
          </a>{" "}
          &{" "}
          <a target="_blank" rel="noreferrer" href="https://moov.io/legal/platform-agreement/">
            Platform Agreement
          </a>
          {". "}
          {invite?.termsOfServiceURL && (
            <>
              You also agree to{" "}
              <a target="_blank" rel="noreferrer" href={invite.termsOfServiceURL}>
                terms provided by {invite.partner?.displayName}
              </a>
              .{" "}
            </>
          )}
          Service specific terms are not applicable until you apply for or use that service,
          whichever is earlier.
        </label>
      </div>

      <FooterButtons
        onBack={() => navigate("../bank-account")}
        onContinue={handleSubmit}
        disabled={!isComplete || !invite || !tosToken || !tosAgreed || isLoading}
        continueLabel="Finish"
        isLoading={isLoading}
      />
    </div>
  );
}
