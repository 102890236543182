import clsx from "clsx";
import styles from "./StatementExampleTable.module.scss";

interface StatementExampleTableProps {
  className?: string;
  fullWidth?: boolean;
  type: "ACH" | "instant";
}

export const StatementExampleTable = ({
  className,
  fullWidth,
  type
}: StatementExampleTableProps) => {
  return (
    <table className={clsx(styles.exampleStatement, fullWidth && styles.fullWidth, className)}>
      <caption className={styles.exampleCaption}>Example transaction</caption>
      <tbody>
        <tr className={styles.exampleRow}>
          <td>
            {type === "ACH" ? (
              <>
                <strong>
                  MV<span className={styles.highlight}>####</span>
                </strong>{" "}
                ACCTVERIFY
              </>
            ) : (
              <>
                RTP deposit from{" "}
                <strong>
                  MV<span className={styles.highlight}>####</span>
                </strong>
              </>
            )}
          </td>
          <td>$0.01</td>
        </tr>
        <tr className={styles.exampleRow}>
          <td>
            <div className={styles.skeleton} />
          </td>
          <td>
            <div className={clsx(styles.skeleton, styles.skeletonShort)} />
          </td>
        </tr>
        <tr className={styles.spacer}>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </table>
  );
};
